<template>
  <div id="logistics">
    <div class="tips" v-if="waybillList.length > 1">
      <svg-icon icon-class="logistics_tips" />当前订单被分成多个包裹发出
    </div>
    <div class="content" :style="waybillList.length > 1 ? '' : 'top:46px'">
      <div class="list" v-for="(list, index) in waybillList" :key="index">
        <div class="list_title">包裹{{ index + 1 }}</div>
        <div class="list_content" @click="WaybillDetails(list)">
          <img :src="list.filePath" alt="" />
          <div class="list_info">
            <div class="top">
              <p>物流公司：{{ list.shipper }}</p>
              <p>物流单号：{{ list.waybillNumber }}</p>
            </div>
            <p class="bottom">
              查看物流详情 <svg-icon icon-class="arrow_right" />
            </p>
          </div>
        </div>
      </div>
    </div>

    <van-action-sheet v-model="logisticsShow" title="物流信息">
      <p style="padding-left: 15px">
        {{ info.shipper }}&nbsp;&nbsp;{{ info.logisticCode }}
      </p>
      <div class="track">
        <van-loading v-if="isLoading" />
        <van-empty description="暂无物流信息" v-else-if="show"> </van-empty>
        <van-steps
          direction="vertical"
          :active="0"
          :active-color="state ? '' : '#FB4E10'"
          :active-icon="state ? 'success' : 'logistics'"
          v-if="!show"
        >
          <van-step v-for="item in info.traces" :key="item.AcceptTime">
            <p>{{ item.AcceptTime }}</p>
            <h3>{{ item.AcceptStation }}</h3>
          </van-step>
        </van-steps>
      </div>
    </van-action-sheet>
    <!-- <div class="track">
      <van-empty description="暂无物流信息" v-if="show"> </van-empty>
      <van-steps
        direction="vertical"
        :active="0"
        :active-color="state ? '' : '#FB4E10'"
        :active-icon="state ? 'success' : 'logistics'"
        v-if="!show"
      >
        <van-step v-for="item in info.traces" :key="item.AcceptTime">
          <p>{{ item.AcceptTime }}</p>
          <h3>{{ item.AcceptStation }}</h3>
        </van-step>
      </van-steps>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "logistics-information",

  data() {
    return {
      waybillList: [],
      info: { traces: [] },
      show: true,
      state: 0,
      isLoading: true,
      logisticsShow: false,
    };
  },
  created() {
    this.waybillList = JSON.parse(sessionStorage.getItem("waybillInfo"));
    if (!this.waybillList) {
      this.$router.go(-1);
    }
  },
  beforeDestroy() {
    sessionStorage.removeItem("waybillInfo");
  },
  methods: {
    async WaybillDetails(val) {
      this.logisticsShow = true;
      const data = await this.$API.order.WaybillDetails({
        object: {
          logisticCode: val.waybillNumber,
          shipperCode: val.shipperCode,
        },
      });
      this.info = data.data.data;
      if (this.info == null) {
        this.isLoading = false;
        return;
      }
      this.info.traces = JSON.parse(this.info.traces).reverse();
      if (this.info.state == 3) this.state = 1;
      this.isLoading = false;
      if (this.info.traces.length) this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#logistics {
  padding-top: 46px;
  .tips {
    background: #f9b515;
    padding: 8px 15px;
    height: 35px;
    color: #fff;
    overflow: hidden;
    .svg-icon {
      font-size: 18px;
      margin-right: 8px;
    }
  }
  .content {
    position: absolute;
    background: #f5f5f5;
    top: 81px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    padding: 12px 15px;
    .list {
      background: #fff;
      margin-bottom: 12px;
      padding: 16px 12px;
      .list_title {
        font-size: 16px;
        font-weight: bold;
        line-height: 23px;
        color: #333333;
        margin-bottom: 12px;
      }
      .list_content {
        display: flex;
        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
          border-radius: 5px;
          margin-right: 12px;
        }
        .list_info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .top {
            font-weight: bold;
            color: #333333;
          }
          .bottom {
            color: #999999;
            .svg-icon {
              font-size: 9px;
              vertical-align: baseline;
            }
          }
        }
      }
    }
    .list:last-child {
      margin: 0;
    }
  }

  .van-popup {
    max-height: 80%;
    .van-action-sheet__header {
      font-weight: bold;
    }
    .track {
      margin-top: 12px;
      padding-bottom: 36px;
      .van-loading {
        padding: 80px 0;
        text-align: center;
      }
      ::v-deep .van-step__title {
        p {
          margin-bottom: 4px;
        }
        h3 {
          font-size: 14px;
        }
      }
    }
  }
  // div {
  //   background: #fff;
  //   border-radius: 5px;
  // }
  // .logistics_company {
  //   font-size: 14px;
  //   font-family: PingFang SC;
  //   font-weight: 400;
  //   color: #333333;
  //   padding: 10px 12px;
  // }
  // .track {
  //   margin-top: 12px;
  //   position: absolute;
  //   top: 54px;
  //   left: 15px;
  //   right: 15px;
  //   bottom: 15px;
  //   overflow: scroll;
  //   ::v-deep .van-step__title {
  //     p {
  //       margin-bottom: 4px;
  //     }
  //     h3 {
  //       font-size: 14px;
  //     }
  //   }
  // }
}
</style>
